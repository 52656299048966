export const paths = {
  // Default
  noMatch: '*',
  root: '/',
  error: '/error',
  notFoundError: '/not-found',
  noPermissions: '/no-permissions',
  forgotPassword: '/forgot-password',
  login: '/login',
  logout: '/logout',
  signup: '/signup',
  thankYou: '/thank-you',
  components: '/components',
  landing: '/landing',
  aboutUs: '/about-us',
  contactUs: '/contact-us',
  pricing: '/pricing',
  termsOfService: '/terms',
  // Ids
  withId: '/:id',
  // Custom paths
  portal: {
    gdpr: '/gdpr',
    mentors: {
      root: '/mentors',
      all: '/all',
      profile: '/profile',
    },
    technologies: {
      root: '/technologies',
      all: '/all',
      details: '/details',
    },
    trainings: {
      root: '/trainings',
      all: '/all',
      corporate: '/corporate',
      kids: '/kids',
    },
    user: {
      root: '/user',
      profile: '/profile',
    },
    teaching: '/teaching',
  },
  transfer: {
    money: {
      import: '/import-money',
      refund: '/refund-money',
    },
  },
  admin: {
    root: '/admin',
  },
};

const { portal, admin, transfer } = paths;

export const pages = {
  noMatch: `${paths.noMatch}`,
  error: {
    generic: `${paths.error}`,
    notFound: `${paths.notFoundError}`,
  },
  noPermissions: paths.noPermissions,
  forgotPassword: paths.forgotPassword,
  login: paths.login,
  logout: paths.logout,
  signup: paths.signup,
  thankYou: paths.thankYou,
  landing: paths.landing,
  aboutUs: paths.aboutUs,
  contactUs: paths.contactUs,
  pricing: paths.pricing,
  portal: {
    home: `${paths.root}`,
    gdpr: `${portal.gdpr}`,
    mentors: {
      root: `${portal.mentors.root}`,
      all: `${portal.mentors.root}${portal.mentors.all}`,
      profile: `${portal.mentors.root}${portal.mentors.profile}${paths.withId}`,
      profileLink: `${portal.mentors.root}${portal.mentors.profile}`,
    },
    technologies: {
      root: `${portal.technologies.root}`,
      all: `${portal.technologies.root}${portal.technologies.all}`,
      details: `${portal.technologies.root}${portal.technologies.details}${paths.withId}`,
      detailsLink: `${portal.technologies.root}${portal.technologies.details}`,
    },
    trainings: {
      root: `${portal.trainings.root}`,
      all: `${portal.trainings.root}${portal.trainings.all}`,
      kids: `${portal.trainings.root}${portal.trainings.kids}${paths.withId}`,
      kidsLink: `${portal.trainings.root}${portal.trainings.kids}`,
      corporate: `${portal.trainings.root}${portal.trainings.corporate}${paths.withId}`,
      corporateLink: `${portal.trainings.root}${portal.trainings.corporate}`,
    },
    user: {
      root: `${portal.user.root}`,
      profile: `${portal.user.cFroot}${portal.user.profile}`,
    },
    teaching: `${portal.teaching}`,
  },
  admin: {
    root: `${admin.root}`,
  },
  transfer: {
    import: `${transfer.money.import}`,
    refund: `${transfer.money.refund}`,
  },
};
