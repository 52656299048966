import React from 'react';
import SimpleFiveColumnFooter from 'components/footers/SimpleFiveColumn';
import { pages } from 'config';

const Footer = () => {
  return (
    <SimpleFiveColumnFooter
      companyDescription=''
      quickLinks={quickLinks}
      productLinks={productLinks}
      legalLinks={legalLinks}
    />
  );
};

export default Footer;

const quickLinks = {
  header: 'Бързи връзки',
  list: [
    {
      title: 'Ментори',
      href: pages.portal.mentors.all,
    },
    {
      title: 'Корпоративни Обучения',
      href: pages.portal.trainings.all,
    },
    {
      title: 'Технологии',
      href: pages.portal.technologies.all,
    },
  ],
};

const productLinks = {
  header: 'Продукт',
  list: [
    {
      title: 'Вход',
      href: pages.login,
    },
  ],
};

const legalLinks = {
  header: 'Правни връзки',
  list: [
    {
      title: 'Политика на поверителност',
      href: pages.portal.gdpr,
    },
    {
      title: 'Условия на ползване',
      href: pages.portal.gdpr,
    },
  ],
};
