import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import TabGrid from 'components/cards/TabCardGrid';
import TwoColVerticalWithButton from 'components/features/TwoColWithTwoFeaturesAndButtons';
import Dot from 'components/symbols/Dot';

import { ReactComponent as BriefcaseIcon } from 'feather-icons/dist/icons/briefcase.svg';
import { ReactComponent as MoneyIcon } from 'feather-icons/dist/icons/dollar-sign.svg';
import { ReactComponent as MapPinIcon } from 'feather-icons/dist/icons/map-pin.svg';
import { ReactComponent as BookOpenIcon } from 'feather-icons/dist/icons/book-open.svg';
import { ReactComponent as SettingsIcon } from 'feather-icons/dist/icons/settings.svg';
import { ReactComponent as MonitorIcon } from 'feather-icons/dist/icons/monitor.svg';

import { openInNewWindow, uuid, getTrainingUrlByType } from 'helpers';

const LocationIcon = tw(MapPinIcon)`mr-1`;
const JobIcon = tw(BriefcaseIcon)`mr-1`;
const LocationContainer = tw.div`ml-2 flex flex-row`;
const JobContainer = tw.div`mr-2 flex flex-row`;
const SubheadingDownContainer = tw.div`mt-2 flex`;

const MentorSubheadingDescription = ({ job, location }) => (
  <SubheadingDownContainer>
    <JobContainer>
      <JobIcon />
      {job && <span>{job}</span>}
    </JobContainer>
    <Dot />
    <LocationContainer>
      <LocationIcon />
      {location && <span>{location}</span>}
    </LocationContainer>
  </SubheadingDownContainer>
);

const MentorProfilePage = () => {
  const navigate = useNavigate();

  const cardClickHandler = (id, type) => {
    const url = getTrainingUrlByType(id, type);
    return navigate(url);
  };

  const previewCVClick = useCallback(() => openInNewWindow(mentorDetails.cvUrl), []);

  const primaryButtonConfig = useMemo(
    () => ({
      as: 'button',
      primaryButtonText: 'Виж автобиография',
      onClick: previewCVClick,
    }),
    [previewCVClick]
  );

  return (
    <>
      <TwoColVerticalWithButton
        heading={mentorDetails.heading}
        subheading={null}
        subheadingDown={<MentorSubheadingDescription job={mentorDetails.job} location={mentorDetails.location} />}
        location={null}
        description={mentorDetails.description}
        imageSrc={mentorDetails.imageUrl}
        buttonRounded={false}
        features={features}
        primaryButtonConfig={primaryButtonConfig}
      />
      <TabGrid
        heading={<>Обучения</>}
        tabs={tabs}
        cardButtonText='Детайли за обучението'
        clickHandler={cardClickHandler}
      />
    </>
  );
};

export default MentorProfilePage;

// TODO: Remove mockups once we introduce Redux toolkit

const features = [
  {
    Icon: MoneyIcon,
    title: 'Заплащане',
    description: 'Цена на час: 15 лв',
  },
  {
    Icon: MonitorIcon,
    title: 'Начини на преподаване',
    description: 'Онлайн / На място',
  },
  {
    Icon: SettingsIcon,
    title: 'Опит',
    description: `Progress - Shaders, OpenGL, Clojure, Unity3D \n  Nelu, LLC - Unity, Augmented Reality (AR), iOS, Android`,
  },
  {
    Icon: BookOpenIcon,
    title: 'Образувание',
    description: 'Технически Университет',
  },
];

const mentorDetails = {
  heading: 'Андрей Митев',
  subheading: 'Програмист',
  job: 'Програмист',
  location: 'София, България',
  imageUrl:
    'https://scontent.fsof10-1.fna.fbcdn.net/v/t1.6435-9/175892161_5362427070465765_6393190222465843550_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=VsBr099fu0wAX-w0Skr&tn=pgXZVkIr4LIaDW1r&_nc_ht=scontent.fsof10-1.fna&oh=00_AfBLsZsZNUZV2deu7w-QUFoH-5ol4IfWijWbx8Vot4vfqg&oe=638F9186',
  // 'https://images.unsplash.com/photo-1582564286939-400a311013a2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80',
  description: `Андрей е програмист от единадесет години. Съвсем наскоро той се фокусира върху
    разширената реалност, изчислителния дизайн и компютърната графика. Той е проектирал
    сложни софтуерни системи, правил е UX дизайн, ръководил е екипи и е говорил на конференции.`,
  imageSrc: '',
  cvUrl: 'https://www.dayjob.com/downloads/CV_examples/test_engineer_CV_template.pdf',
};

const tabs = {
  Корпоративни: [
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/2gqVi4hhjq9vgvdh63UoKZ/c763c6f7e98a80eb2800bbe5eb9d690d/react_native_zero_to_mastery.png?w=800&h=449&q=50&fm=webp',
      title: 'ReactJS',
      content: null,
      description: 'Научете се да програмирате',
      price: '199.99 лв',
      url: '',
      duration: '15 часа',
      lessons: '15 урока',
      type: 'corporate',
    },
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/2Suf3pyuUdK6bxjR5jcaSE/4b9f3f86834a3ce65d1c83e3a11c8dcd/rust_programming_zero_to_mastery.png?w=800&h=450&q=50&fm=webp',
      title: 'Rust',
      content: null,
      description: 'Научете се да програмирате',
      price: '99 лв',
      url: '',
      duration: '15 часа',
      lessons: '15 урока',
      type: 'corporate',
    },
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/2wqtcZLn5BirJ42EDfoair/be61835b9f2afa0b4e85a317a436650c/Python_Cover.jpg?w=750&h=422&q=50&fm=webp',
      title: 'Python Advanced',
      content: null,
      description: 'Научете се да програмирате',
      price: '99 лв',
      url: '',
      duration: '8 часа',
      lessons: '15 урока',
      type: 'corporate',
    },
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/13733PvKmnyWI3N3bBmQH3/bfb09ac1afeb6f8594688f12947b44dd/complete_sql_database_zero_to_mastery.png?w=800&h=450&q=50&fm=webp',
      title: 'Databases',
      content: null,
      description: 'Научете се да програмирате',
      price: '245 лв',
      url: '',
      duration: '50 часа',
      lessons: '15 урока',
      type: 'corporate',
    },
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/llkUpPk1NrKR5BMEcfyrc/42ebd1b27f3adb25c4a73fa6de52cd06/advanced_javascript_concepts_cover_photo_new.png?w=800&h=450&q=50&fm=webp',
      title: 'Javascript - Front End',
      content: null,
      description: 'Научете се да програмирате',
      price: '132 лв',
      url: '',
      duration: '10 часа',
      lessons: '10 урока',
      type: 'corporate',
    },
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/1zD5vKKiY0hUL1MAGB6bn2/137427172c25ab3409c50c040cbdfc98/JTS.png?w=800&h=450&q=50&fm=webp',
      title: 'Java',
      content: null,
      description: 'Научете се да програмирате',
      price: '55 лв',
      url: '',
      duration: '15 часа',
      lessons: '15 урока',
      type: 'corporate',
    },
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/7cS8gBoWulxkWNWEm0FspJ/c7eb42dd82e27279307f8b9fc9b136fa/nodejs_cover_photo_smaller_size.png?w=800&h=450&q=50&fm=webp',
      title: 'NodeJS',
      content: null,
      description: 'Научете се да програмирате',
      price: '199 лв',
      url: '',
      duration: '25 часа',
      lessons: '25 урока',
      type: 'corporate',
    },
  ],
  Технологии: [
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/13733PvKmnyWI3N3bBmQH3/bfb09ac1afeb6f8594688f12947b44dd/complete_sql_database_zero_to_mastery.png?w=800&h=450&q=50&fm=webp',
      title: 'SQL',
      content: null,
      description: 'Научете се да програмирате',
      price: '245 лв',
      url: '',
      duration: '50 часа',
      lessons: '15 урока',
      type: 'technologies',
    },
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/1zD5vKKiY0hUL1MAGB6bn2/137427172c25ab3409c50c040cbdfc98/JTS.png?w=800&h=450&q=50&fm=webp',
      title: 'Java',
      content: null,
      description: 'Научете се да програмирате',
      price: '55 лв',
      url: '',
      duration: '15 часа',
      lessons: '15 урока',
      type: 'technologies',
    },
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/llkUpPk1NrKR5BMEcfyrc/42ebd1b27f3adb25c4a73fa6de52cd06/advanced_javascript_concepts_cover_photo_new.png?w=800&h=450&q=50&fm=webp',
      title: 'Javascript',
      content: null,
      description: 'Научете се да програмирате',
      price: '132 лв',
      url: '',
      duration: '11 часа',
      lessons: '15 урока',
      type: 'technologies',
    },
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/2wqtcZLn5BirJ42EDfoair/be61835b9f2afa0b4e85a317a436650c/Python_Cover.jpg?w=750&h=422&q=50&fm=webp',
      title: 'Python',
      content: null,
      description: 'Научете се да програмирате',
      price: '99 лв',
      url: '',
      duration: '9.5 часа',
      lessons: '15 урока',
      type: 'technologies',
    },

    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/75XmCBS35kqsky2S2mtuoP/6938210a367b1e151bcd9edb69b842a5/image.png?w=700&fm=webp',
      title: 'Designer',
      content: null,
      description: 'Научете се да програмирате',
      price: '99 лв',
      url: '',
      duration: '10 часа',
      lessons: '15 урока',
      type: 'technologies',
    },
  ],
  Деца: getRandomCards().splice(0, 2),
};

// TODO: Remove mockups once we introduce Redux toolkit

/* This function is only there for demo purposes. It populates placeholder cards */
function getRandomCards() {
  const cards = [
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/34oTrOaPCY3VKnubPKZCKv/a8eb7d89a56594d9d5acb91252b451b5/MTCI.png?w=800&h=450&q=50&fm=webp',
      title: 'Master Coding',
      content: 'Learn to code from scratch and get hired as a Web Developer in 2023.',
      description: 'Научете се да програмирате',
      price: '44 лв',
      url: '',
      duration: '15 часа',
      lessons: '15 урока',
      type: 'kids',
    },
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/1zD5vKKiY0hUL1MAGB6bn2/137427172c25ab3409c50c040cbdfc98/JTS.png?w=800&h=450&q=50&fm=webp',
      title: 'HTML',
      content: null,
      description: 'Научете се да програмирате',
      price: '55 лв',
      url: '',
      duration: '15 часа',
      lessons: '15 урока',
      type: 'kids',
    },
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/34oTrOaPCY3VKnubPKZCKv/a8eb7d89a56594d9d5acb91252b451b5/MTCI.png?w=800&h=450&q=50&fm=webp',
      title: 'Master Coding',
      content: 'Learn to code from scratch and get hired as a Web Developer in 2023.',
      description: 'Научете се да програмирате',
      price: '44 лв',
      url: '',
      duration: '15 часа',
      lessons: '15 урока',
      type: 'corporate',
    },
  ];

  // Shuffle array
  return cards.sort(() => Math.random() - 0.5);
}
