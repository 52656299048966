import React from 'react';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line

import LogoImage from 'assets/images/logo.svg';

const Container = tw.div`relative bg-gray-100 -mx-8 -mb-8 px-8`;
const FourColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-around`;

const Column = tw.div`md:w-1/5`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black text-primary-500`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const FooterColumn = ({ header, links }) => {
  return (
    <Column>
      <ColumnHeading>{header}</ColumnHeading>
      <LinkList>
        {links.map(({ href, title }, key) => (
          <LinkListItem key={key}>
            <Link href={href}>{title}</Link>
          </LinkListItem>
        ))}
      </LinkList>
    </Column>
  );
};

// Each link has a title & url
export default ({
  companyDescription = 'Mentorship is an Internet Technology company providing design resources such as website templates and themes.',
  quickLinks = {
    header: 'Quick Links',
    list: defaultQuickLinks,
  },
  productLinks = {
    header: 'Product',
    list: defaultProductLinks,
  },
  legalLinks = {
    header: 'Legal',
    list: defaultLegalLinks,
  },
}) => {
  const showQuickLinks = getCanShowLink(quickLinks.list);
  const showLegalLinks = getCanShowLink(legalLinks.list);
  const showProductLinks = getCanShowLink(productLinks.list);

  return (
    <Container>
      <FourColumns>
        <Column>
          <LogoContainer>
            <LogoImg src={LogoImage} />
            <LogoText>Mentorship</LogoText>
          </LogoContainer>
          {companyDescription && <CompanyDescription>{companyDescription}</CompanyDescription>}
        </Column>
        {showQuickLinks && <FooterColumn header={quickLinks.header} links={quickLinks.list} />}
        {showLegalLinks && <FooterColumn header={legalLinks.header} links={legalLinks.list} />}
        {showProductLinks && <FooterColumn header={productLinks.header} links={productLinks.list} />}
      </FourColumns>
    </Container>
  );
};

const getCanShowLink = (link) => link.length > 0;

const defaultQuickLinks = [
  {
    title: 'Blog',
    href: '#',
  },
  {
    title: 'FAQs',
    href: '#',
  },
  {
    title: 'Support',
    href: '#',
  },
];

const defaultProductLinks = [
  {
    title: 'Login',
    href: '#',
  },
];

const defaultLegalLinks = [
  {
    title: 'GDPR',
    href: '#',
  },
  {
    title: 'Privacy Policy',
    href: '#',
  },
  {
    title: 'Terms of Service',
    href: '#',
  },
];
