import React from 'react';
import { useNavigate } from 'react-router-dom';

import TabGrid from 'components/cards/TabCardGrid';
import { uuid, getTrainingUrlByType } from 'helpers';

const TechnologyDetailsPage = () => {
  const navigate = useNavigate();

  const cardClickHandler = (id, type) => {
    const url = getTrainingUrlByType(id, type);
    return navigate(url);
  };

  return (
    <TabGrid
      heading={<>Обучения</>}
      tabs={tabs}
      cardButtonText='Детайли за обучението'
      clickHandler={cardClickHandler}
    />
  );
};

export default TechnologyDetailsPage;

const tabs = {
  Корпоративни: [
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/2gqVi4hhjq9vgvdh63UoKZ/c763c6f7e98a80eb2800bbe5eb9d690d/react_native_zero_to_mastery.png?w=800&h=449&q=50&fm=webp',
      title: 'ReactJS',
      content: null,
      description: 'Научете се да програмирате',
      price: '199.99 лв',
      url: '',
      duration: '15 часа',
      lessons: '15 урока',
      type: 'corporate',
    },
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/7cS8gBoWulxkWNWEm0FspJ/c7eb42dd82e27279307f8b9fc9b136fa/nodejs_cover_photo_smaller_size.png?w=800&h=450&q=50&fm=webp',
      title: 'NodeJS',
      content: null,
      description: 'Научете се да програмирате',
      price: '199 лв',
      url: '',
      duration: '25 часа',
      lessons: '25 урока',
      type: 'corporate',
    },
  ],
};
