import React from 'react';
import { useNavigate } from 'react-router-dom';

import TabGrid from 'components/cards/TabCardGrid';
import { uuid } from 'helpers';
import { pages } from 'config/paths';

const TechnologiesAllPage = () => {
  const navigate = useNavigate();

  const cardClickHandler = (id) => {
    const url = `${pages.portal.technologies.detailsLink}/${id}`;
    return navigate(url);
  };

  return (
    <TabGrid
      heading={<>Технологии</>}
      tabs={tabs}
      cardButtonText='Детайли за технологията'
      clickHandler={cardClickHandler}
    />
  );
};

export default TechnologiesAllPage;

// TODO: Remove mockups once we introduce Redux toolkit

/**
 * We have different technologies which we teach in our courses.
 * Inside each technology page we have all related courses for the specific technology.
 */

const tabs = {
  Технологии: [
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/llkUpPk1NrKR5BMEcfyrc/42ebd1b27f3adb25c4a73fa6de52cd06/advanced_javascript_concepts_cover_photo_new.png?w=800&h=450&q=50&fm=webp',
      title: 'Javascript',
      content: null,
      description: 'Научете се да програмирате',
      price: '132 лв',
      url: '',
      duration: '11 часа',
      lessons: '15 урока',
      type: 'technologies',
    },
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/13733PvKmnyWI3N3bBmQH3/bfb09ac1afeb6f8594688f12947b44dd/complete_sql_database_zero_to_mastery.png?w=800&h=450&q=50&fm=webp',
      title: 'SQL',
      content: null,
      description: 'Научете се да програмирате',
      price: '245 лв',
      url: '',
      duration: '50 часа',
      lessons: '15 урока',
      type: 'technologies',
    },
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/1zD5vKKiY0hUL1MAGB6bn2/137427172c25ab3409c50c040cbdfc98/JTS.png?w=800&h=450&q=50&fm=webp',
      title: 'C#',
      content: null,
      description: 'Научете се да програмирате',
      price: '55 лв',
      url: '',
      duration: '15 часа',
      lessons: '15 урока',
      type: 'technologies',
    },
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/2wqtcZLn5BirJ42EDfoair/be61835b9f2afa0b4e85a317a436650c/Python_Cover.jpg?w=750&h=422&q=50&fm=webp',
      title: 'Python',
      content: null,
      description: 'Научете се да програмирате',
      price: '99 лв',
      url: '',
      duration: '9.5 часа',
      lessons: '15 урока',
      type: 'technologies',
    },

    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/75XmCBS35kqsky2S2mtuoP/6938210a367b1e151bcd9edb69b842a5/image.png?w=700&fm=webp',
      title: 'Java',
      content: null,
      description: 'Научете се да програмирате',
      price: '99 лв',
      url: '',
      duration: '10 часа',
      lessons: '15 урока',
      type: 'technologies',
    },
  ],
};
