import React from 'react';
import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';

// Demo pages
import MainLandingPage from 'pages/demos/MainLandingPage';
import ThankYouPage from 'pages/demos/ThankYouPage';
import ComponentRenderer from 'pages/demos/ComponentRenderer';

// All main pages
import AboutUsPage from 'pages/AboutUsPage';
import ContactUsPage from 'pages/ContactUsPage';
import LoginPage from 'pages/LoginPage';
import SignupPage from 'pages/SignupPage';
import PricingPage from 'pages/PricingPage';
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage';
import TermsOfServicePage from 'pages/TermsOfServicePage';
import HomePage from 'pages/HomePage';
import TeachingPage from 'pages/TeachingPage';
import ProfilePage from 'pages/ProfilePage';

// Generic pages
import ErrorPage from 'pages/generic/ErrorPage';
import NotFoundPage from 'pages/generic/NotFoundPage';
import NoPermissionsPage from 'pages/generic/NoPermissionsPage';

// Mentor pages
import MentorsPage from 'pages/mentor/MentorsPage';
import MentorProfilePage from 'pages/mentor/MentorProfilePage';
import MentorsAllPage from 'pages/mentor/MentorsAllPage';

// Training pages
import TrainingsPage from 'pages/trainings/TrainingsPage';
import CorpotateTrainingsPage from 'pages/trainings/CorpotateTrainingsPage';
import KidsTrainingsPage from 'pages/trainings/KidsTrainingsPage';
import TrainingsAllPage from 'pages/trainings/TrainingsAllPage';

// Transfer pages
import ImportMoneyPage from 'pages/transfer/ImportMoneyPage';
import RefundMoneyPage from 'pages/transfer/RefundMoneyPage';

// Admin pages
import AdminPage from 'pages/admin/AdminPage';

// Technologies pages
import TechnologiesPage from 'pages/Technologies/TechnologiesPage';
import TechnologiesAllPage from 'pages/Technologies/TechnologiesAllPage';
import TechnologyDetailsPage from 'pages/Technologies/TechnologyDetailsPage';

import { pages } from 'config';

export const Routes = () => {
  return (
    <RouterRoutes>
      {/* Treact demo pages */}
      <Route path='/components/:type/:subtype/:name' element={<ComponentRenderer />} />
      <Route path='/components/:type/:name' element={<ComponentRenderer />} />
      <Route path={pages.thankYou} element={<ThankYouPage />} />
      <Route path={pages.landing} element={<MainLandingPage />} />
      {/* App pages */}
      <Route path={pages.error.generic} element={<ErrorPage />} />
      <Route path={pages.error.notFound} element={<NotFoundPage />} />
      <Route path={pages.noPermissions} element={<NoPermissionsPage />} />
      {/* <Route path={pages.forgotPassword} element={<ForgotPasswordPage />} /> */}
      <Route path={pages.aboutUs} element={<AboutUsPage />} />
      <Route path={pages.contactUs} element={<ContactUsPage />} />
      <Route path={pages.login} element={<LoginPage />} />
      <Route path={pages.signup} element={<SignupPage />} />
      <Route path={pages.pricing} element={<PricingPage />} />
      <Route path={pages.termsOfService} element={<TermsOfServicePage />} />
      <Route path={pages.admin.root} element={<AdminPage />} />
      <Route path={pages.portal.home} element={<HomePage />} />
      <Route path={pages.portal.gdpr} element={<PrivacyPolicyPage />} />
      <Route path={pages.portal.teaching} element={<TeachingPage />} />
      <Route path={pages.transfer.import} element={<ImportMoneyPage />} />
      <Route path={pages.transfer.refund} element={<RefundMoneyPage />} />
      {/* Mentors */}
      <Route path={pages.portal.mentors.root} element={<MentorsPage />}>
        <Route path={pages.portal.mentors.profile} element={<MentorProfilePage />} />
        <Route path={pages.portal.mentors.all} element={<MentorsAllPage />} />
      </Route>
      {/* Technologies */}
      <Route path={pages.portal.user.profile} element={<ProfilePage />} />
      <Route path={pages.portal.technologies.root} element={<TechnologiesPage />}>
        <Route path={pages.portal.technologies.all} element={<TechnologiesAllPage />} />
        <Route path={pages.portal.technologies.details} element={<TechnologyDetailsPage />} />
      </Route>
      {/* Trainings */}
      <Route path={pages.portal.trainings.root} element={<TrainingsPage />}>
        <Route path={pages.portal.trainings.corporate} element={<CorpotateTrainingsPage />} />
        <Route path={pages.portal.trainings.kids} element={<KidsTrainingsPage />} />
        <Route path={pages.portal.trainings.all} element={<TrainingsAllPage />} />
      </Route>
      <Route path={pages.noMatch} element={<Navigate to={pages.error.notFound} replace />} />
    </RouterRoutes>
  );
};
