import React from 'react';
import { useNavigate } from 'react-router-dom';

import TabGrid from 'components/cards/TabCardGrid';
import { uuid } from 'helpers';
import { pages } from 'config/paths';

const TrainingsAllPage = () => {
  const navigate = useNavigate();

  const cardClickHandler = (id) => {
    const url = `${pages.portal.trainings.corporateLink}/${id}`;
    return navigate(url);
  };

  return (
    <div>
      <TabGrid
        heading={<>Корпоративни Обучения</>}
        tabs={tabs}
        cardButtonText='Детайли за обучението'
        clickHandler={cardClickHandler}
      />
    </div>
  );
};

export default TrainingsAllPage;

// TODO: Remove mockups once we introduce Redux toolkit

const tabs = {
  Корпоративни: [
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/2gqVi4hhjq9vgvdh63UoKZ/c763c6f7e98a80eb2800bbe5eb9d690d/react_native_zero_to_mastery.png?w=800&h=449&q=50&fm=webp',
      title: 'ReactJS',
      content: null,
      description: 'Научете се да програмирате',
      price: '199.99 лв',
      url: '',
      duration: '15 часа',
      lessons: '15 урока',
      type: 'corporate',
    },
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/2Suf3pyuUdK6bxjR5jcaSE/4b9f3f86834a3ce65d1c83e3a11c8dcd/rust_programming_zero_to_mastery.png?w=800&h=450&q=50&fm=webp',
      title: 'Rust',
      content: null,
      description: 'Научете се да програмирате',
      price: '99 лв',
      url: '',
      duration: '15 часа',
      lessons: '15 урока',
      type: 'corporate',
    },
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/2wqtcZLn5BirJ42EDfoair/be61835b9f2afa0b4e85a317a436650c/Python_Cover.jpg?w=750&h=422&q=50&fm=webp',
      title: 'Python Advanced',
      content: null,
      description: 'Научете се да програмирате',
      price: '99 лв',
      url: '',
      duration: '8 часа',
      lessons: '15 урока',
      type: 'corporate',
    },
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/13733PvKmnyWI3N3bBmQH3/bfb09ac1afeb6f8594688f12947b44dd/complete_sql_database_zero_to_mastery.png?w=800&h=450&q=50&fm=webp',
      title: 'Databases',
      content: null,
      description: 'Научете се да програмирате',
      price: '245 лв',
      url: '',
      duration: '50 часа',
      lessons: '15 урока',
      type: 'corporate',
    },
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/llkUpPk1NrKR5BMEcfyrc/42ebd1b27f3adb25c4a73fa6de52cd06/advanced_javascript_concepts_cover_photo_new.png?w=800&h=450&q=50&fm=webp',
      title: 'Javascript - Front End',
      content: null,
      description: 'Научете се да програмирате',
      price: '132 лв',
      url: '',
      duration: '10 часа',
      lessons: '10 урока',
      type: 'corporate',
    },
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/1zD5vKKiY0hUL1MAGB6bn2/137427172c25ab3409c50c040cbdfc98/JTS.png?w=800&h=450&q=50&fm=webp',
      title: 'Java',
      content: null,
      description: 'Научете се да програмирате',
      price: '55 лв',
      url: '',
      duration: '15 часа',
      lessons: '15 урока',
      type: 'corporate',
    },
    {
      id: uuid(),
      imageSrc:
        'https://images.ctfassets.net/aq13lwl6616q/7cS8gBoWulxkWNWEm0FspJ/c7eb42dd82e27279307f8b9fc9b136fa/nodejs_cover_photo_smaller_size.png?w=800&h=450&q=50&fm=webp',
      title: 'NodeJS',
      content: null,
      description: 'Научете се да програмирате',
      price: '199 лв',
      url: '',
      duration: '25 часа',
      lessons: '25 урока',
      type: 'corporate',
    },
  ],
};
