import React from 'react';

import AnimationRevealPage from 'helpers/AnimationRevealPage';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <AnimationRevealPage disabled>
      <Header />
      {children}
      <Footer />
    </AnimationRevealPage>
  );
};

export default Layout;
