import React from 'react';
import ThreeColSlider from 'components/cards/ThreeColSlider';

/* Change this according to your needs */
const coachesCards = [
  {
    imageSrc:
      'https://scontent.fsof10-1.fna.fbcdn.net/v/t39.30808-6/275025561_5070287256355677_8096773602994362687_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=dk40Gdg3RPQAX-ySG81&tn=pgXZVkIr4LIaDW1r&_nc_ht=scontent.fsof10-1.fna&oh=00_AfBfG6NmZv1kbpCNxEejxNn28TDL4OOKkvIfmsxic41mjw&oe=636C7067',
    title: 'Dimitar Yanis',
    description:
      'Lorem ipsum dolor sit amet, consectur dolori adipiscing elit, sed do eiusmod tempor nova incididunt ut labore et dolore magna aliqua.',
    pricingText: 'BGN 39-50/Hour',
    rating: '4.8',
  },
  {
    imageSrc:
      'https://scontent.fsof10-1.fna.fbcdn.net/v/t1.6435-9/175892161_5362427070465765_6393190222465843550_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=VsBr099fu0wAX-w0Skr&tn=pgXZVkIr4LIaDW1r&_nc_ht=scontent.fsof10-1.fna&oh=00_AfBLsZsZNUZV2deu7w-QUFoH-5ol4IfWijWbx8Vot4vfqg&oe=638F9186',
    title: 'Andrey Mitev',
    description:
      'Lorem ipsum dolor sit amet, consectur dolori adipiscing elit, sed do eiusmod tempor nova incididunt ut labore et dolore magna aliqua.',
    pricingText: 'BGN 50-70/Hour',
    rating: '4.9',
  },
  {
    imageSrc:
      'https://scontent.fsof10-1.fna.fbcdn.net/v/t1.18169-9/12108011_1201080219906328_8443966898377292985_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=w16GgnqIixEAX9TM9OX&_nc_ht=scontent.fsof10-1.fna&oh=00_AfCeqqBrHu2HpDC0sSiKiVNFTKfkkysfiBk-MbqnowxdZw&oe=638FA1EF',
    title: 'Veselin Gogoshev',
    description:
      'Lorem ipsum dolor sit amet, consectur dolori adipiscing elit, sed do eiusmod tempor nova incididunt ut labore et dolore magna aliqua.',
    pricingText: 'BGN 19-30/Hour',
    rating: '5.0',
  },
  {
    imageSrc:
      'https://scontent.fsof10-1.fna.fbcdn.net/v/t39.30808-6/306359985_10225791888322628_7030886370833223988_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=hB5pZKp0gyAAX_GRi6Z&_nc_ht=scontent.fsof10-1.fna&oh=00_AfCF8oBIJqHHSt-6JDjQOi8FBw32JqDkZVNQj4XpNzUQSg&oe=636D7373',
    title: 'David Parushev',
    description:
      'Lorem ipsum dolor sit amet, consectur dolori adipiscing elit, sed do eiusmod tempor nova incididunt ut labore et dolore magna aliqua.',
    pricingText: 'BGN 99-120/Hour',
    rating: '4.5',
  },
];

const HomePage = () => {
  return <ThreeColSlider heading='Популярни ментори' cards={coachesCards} primaryButtonText='Преглед' />;
};

export default HomePage;
