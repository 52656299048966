import React from 'react';

import LibHeader, { NavLinks, NavLink, PrimaryLink } from '../headers/light';
import { pages } from 'config';

const Header = () => {
  return <LibHeader links={links} />;
};

export default Header;

const links = [
  <NavLinks key={1}>
    <NavLink href={pages.portal.mentors.all}>Ментори</NavLink>
    <NavLink href={pages.portal.technologies.all}>Технологии</NavLink>
    <NavLink href={pages.portal.trainings.all}>Корпоративни обучения</NavLink>
    <NavLink href={pages.login} tw='lg:ml-12!'>
      Вход
    </NavLink>
    <PrimaryLink href={pages.signup}>Регистрация</PrimaryLink>
  </NavLinks>,
];
