import { pages } from 'config/paths';
import { TRANING_TYPES } from '../constants';

export const getTrainingUrlByType = (id, type) => {
  let url = pages.portal.trainings.root;

  switch (type) {
    case TRANING_TYPES.CORPORATE:
      url = `${pages.portal.trainings.corporateLink}/${id}`;
      return url;
    case TRANING_TYPES.KIDS:
      url = `${pages.portal.trainings.kidsLink}/${id}`;
      return url;
    case TRANING_TYPES.TECHNOLOGIES:
      url = `${pages.portal.technologies.detailsLink}/${id}`;
      return url;
    default:
      return url;
  }
};
